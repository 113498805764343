module.exports = {
	title: "SMART HOME by Hornbach - Portal",
	serverOptions: [
		{
			label: "DEV",
			id: "dev",
			channel: "hornbach",
			docsVersion: "develop",
			hostname: "dev.smarthomebyhornbach.com",
			accessCookieConfig: {
				name: "glient2idhornbach",
				path: "/glient2/hornbach",
				domain: ".dev.smarthomebyhornbach.com",
			},
			refreshCookieConfig: {
				name: "refreshhornbach",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
				domain: ".dev.smarthomebyhornbach.com",
			},
			glientWsUrl: "wss://api-mgmt.dev.smarthomebyhornbach.com/glient2/hornbach",
			gupportWsUrl: "wss://api-mgmt.dev.smarthomebyhornbach.com/gupport2/hornbach",
			ccWsUrl: "wss://api-mgmt.dev.smarthomebyhornbach.com/cc2/hornbach",
			redirectUrl: "https://dev.smarthomebyhornbach.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/redirect",
			revokeUrl: "https://dev.smarthomebyhornbach.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/logout",
			invalidateTokenUrl: "https://dev.smarthomebyhornbach.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
			editAccountUrl: "https://dev.smarthomebyhornbach.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
		{
			label: "ALPHA",
			id: "alpha",
			channel: "hornbach",
			docsVersion: "develop",
			hostname: "alpha.roc-connect.com",
			accessCookieConfig: {
				name: "glient2idhornbach",
				path: "/glient2/hornbach",
				domain: ".alpha.roc-connect.com",
			},
			refreshCookieConfig: {
				name: "refreshhornbach",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
				domain: ".alpha.roc-connect.com",
			},
			glientWsUrl: "wss://alpha.roc-connect.com/glient2/hornbach",
			gupportWsUrl: "wss://alpha.roc-connect.com/gupport2/hornbach",
			ccWsUrl: "wss://alpha.roc-connect.com/cc2/hornbach",
			redirectUrl: "https://alpha.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/redirect",
			revokeUrl: "https://alpha.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/logout",
			invalidateTokenUrl: "https://alpha.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
			editAccountUrl: "https://alpha.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
		{
			label: "SHHB",
			id: "prod",
			channel: "hornbach",
			docsVersion: "public",
			hostname: "prod.smarthomebyhornbach.com",
			accessCookieConfig: {
				name: "glient2idhornbach",
				path: "/glient2/hornbach",
				domain: ".prod.smarthomebyhornbach.com",
			},
			refreshCookieConfig: {
				name: "refreshhornbach",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
				domain: ".prod.smarthomebyhornbach.com",
			},
			glientWsUrl: "wss://api-mgmt.prod.smarthomebyhornbach.com/glient2/hornbach",
			gupportWsUrl: "wss://api-mgmt.prod.smarthomebyhornbach.com/gupport2/hornbach",
			ccWsUrl: "wss://api-mgmt.prod.smarthomebyhornbach.com/cc2/hornbach",
			redirectUrl: "https://prod.smarthomebyhornbach.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/redirect",
			revokeUrl: "https://prod.smarthomebyhornbach.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/logout",
			invalidateTokenUrl: "https://prod.smarthomebyhornbach.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
			// editAccountUrl: "",
		},
	],
};
// {
// 	glientWsUrl: "wss://factoryleader.roc-connect.com/glient25/hornbach",
// 	gupportWsUrl: "wss://factoryleader.roc-connect.com/gupport2/hornbach",
// 	ccWsUrl: "wss://factoryleader.roc-connect.com/cc2/hornbach",
// 	redirectUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/redirect",
// 	revokeUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach/logout",
// 	invalidateTokenUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/hornbach",
// 	editAccountUrl: "https://factoryleader.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
// }
